<script>
import Modal from "@/components/Modal.vue";

export default {
  name: "PaymentModal",
  props: {
    url: {
      type: String,
      default: null,
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
      show: true,
    };
  },
  mounted() {
    const iframe = document.getElementById("3d-secure-iframe");
    const interval = setInterval(() => {
      if (!iframe) {
        return;
      }
      if (!iframe['contentWindow']) {
        return;
      }
      if (iframe.contentWindow?.location) {
        if (iframe.contentWindow?.location?.href.includes("subscription")) {
          clearInterval(interval);
          this.closeQueueModal();
        }
      }
    }, 100);
  },
  setup() {
  },
  methods: {
    closeQueueModal() {
      this.$emit("close");
    },
  },
};
</script>

<template>
  <modal v-model:show="show" :size="'lg'" @close="closeQueueModal" >
    <div style="height: 80vh">
      <iframe
        id="3d-secure-iframe"
        ref="paymentframe"
        :src="url"
        frameborder="0"
        class="moyasar-popup"
        height="100%"
        width="100%"
      ></iframe>
    </div>
  </modal>
</template>

<style scoped></style>
