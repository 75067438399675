<template>
  <div style="position: unset" v-if="userPlan && userPlan.name === 'Free'">
    <div class="container-block">
      <div class="container-block-blur-overlay"></div>
      <div class="container-block-message">
        <h1>This feature is only available for premium users.</h1>
        <p>To unlock this feature, please subscribe to one of our premium plans.</p>
        <router-link to="/subscriptions">
          <base-button type="primary">Subscribe</base-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'BlurredBackgroundMessage',
  computed: {
    ...mapGetters(["userPlan"]),
  },
}
</script>

<style scoped>
.container-block {
  height: 100%;
  background-size: cover;
  background-position: center;
}

.container-block-blur-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  z-index: 100;
}

.container-block-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: #333;
  text-align: center;
  padding: 20px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  z-index: 101;
}
</style>
