<template>
  <footer class="py-5" id="footer-main">
    <div class="container">
      <div class="row align-items-center justify-content-xl-between">
        <div class="col">
          <div class="copyright text-center text-xl-left text-muted">
            &copy; {{ year }}
            <a
              href="https://www.wesla.sa"
              class="font-weight-bold ml-1"
              target="_blank"
              >Wesla</a
            >
          </div>
        </div>
        <div class="col">
          <ul
            class="nav nav-footer justify-content-center justify-content-xl-end"
          >
            <li class="nav-item">
              <router-link to="/terms" class="nav-link"
                >Terms & Conditions</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "MainFooter",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style lang="scss"></style>
