<template>
  <div class="main-content">
    <nav
      id="navbar-main"
      class="navbar navbar-horizontal navbar-main navbar-expand-lg navbar-dark bg-primary"
    >
      <div class="container">
        <router-link to="/" class="navbar-brand">
          <img src="/img/landing/logo.png" />
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbar-collapse"
          aria-controls="navbar-collapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="navbar-collapse navbar-custom-collapse collapse justify-content-end"
          id="navbar-collapse"
        >
          <div class="navbar-collapse-header">
            <div class="row">
              <div class="col-6 collapse-brand">
                <router-link to="/">
                  <img src="/img/landing/logo.png" />
                </router-link>
              </div>
              <div class="col-6 collapse-close">
                <button
                  type="button"
                  class="navbar-toggler"
                  data-toggle="collapse"
                  data-target="#navbar-collapse"
                  aria-controls="navbar-collapse"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span></span>
                  <span></span>
                </button>
              </div>

              <RouterLink
                to="/login"
                class="btn btn-neutral btn-icon center mt-4"
              >
                <span class="btn-inner--icon">
                  <i class="fas fa-store mr-2"></i>
                </span>
                <span class="nav-link-inner--text">Merchant Dashboard</span>
              </RouterLink>
            </div>
          </div>

          <!--          Mobile item-->

          <ul class="navbar-nav">
             <li class="nav-item d-none d-lg-block ml-lg-4">
              <button @click="changeLang" class="btn btn-icon text-light">
                <span class="nav-link-inner--text">{{ $t('lang') }}</span>
              </button>
            </li>
            <li class="nav-item d-none d-lg-block ml-lg-4">
              <RouterLink to="/login" class="btn btn-neutral btn-icon">
                <span class="btn-inner--icon">
                  <i class="fas fa-store mr-2"></i>
                </span>
                <span class="nav-link-inner--text">{{ $t('merchantLogin') }}</span>
              </RouterLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <router-view></router-view>

    <MainFooter></MainFooter>
  </div>
</template>
<script>
import MainFooter from "@/views/Layout/MainFooter.vue";

export default {
  name: "MainLayout",
  components: {
    MainFooter,
  },
  methods: {
    changeLang() {
      this.$i18n.locale = this.$i18n.locale === "en" ? "ar" : "en";
    },
  },
  mounted() {},
};
</script>
<style lang="scss"></style>
