<template>
  <div class="container-fluid">
    <div class="pb-5 mt-2">
      <div class="row justify-content-center">
        <div class="col-lg-10 card-wrapper">
          <!-- Grid system -->
          <card>
            <!-- Card body -->

            <p class="card-text mb-4 font-weight-bold">Do you have a code to redeem?</p>
            <Form @submit="onSubmit" :validation-schema="schema">
              <base-input
                :disabled="loading"
                name="Code"
                label="Code"
                addon-left-icon="fa fa-user-circle"
                v-model="model.code"
                placeholder="x123 Welcome Merchant!"
              />

              <small class="font-weight-bolder text-danger">{{
                error_message
              }}</small>
              <base-button
                type="success"
                native-type="submit"
                class="my-4"
                :disabled="loading"
                >Redeem
              </base-button>
            </Form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import * as Yup from "yup";
import Card from "@/components/Cards/Card.vue";
import {mapActions} from "vuex";

export default {
  name: "Redeem",
  data() {
    return {
      model: {},
      error_message: "",
      loading: false,
    };
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      this.loading = true;
      this.$api
        .post("/subscription/redeem", this.model)
        .then((res) => {
          const message = res.data.message || "Code redeemed successfully";
          this.$runToast("top-right", "success", message);
          this.$emit("checkPayment", res.data.data); // checkPayment
        })
        .catch((error) => {
          const message = error.response.data.message || "An error occurred";
          this.$runToast("top-right", "error", message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const schema = Yup.object().shape({
      Code: Yup.string().required(),
    });

    return {
      schema,
    };
  },
  components: { Card, BaseInput, BaseButton },
};
</script>
