<template>
  <div class="wrapper">
    <!-- <notifications></notifications> -->
    <side-bar
      v-if="!$route.meta.hideSidebar"
      v-show="!loadingInfo && !getNeedInformation"
    >
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'fa fa-home text-primary',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Stores',
            path: '/dashboard/stores',
            icon: 'fas fa-store text-primary',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Subscriptions',
            path: '/subscriptions',
            icon: 'fas fa-credit-card text-primary',
          }"
        >
        </sidebar-item>
      </template>

      <template v-slot:links-after>
<!--        <hr class="my-3" />-->
<!--        <h6 class="navbar-heading p-0 text-muted">Documentation</h6>-->

<!--        <ul class="navbar-nav mb-md-3">-->
<!--          <li class="nav-item">-->
<!--            <a class="nav-link" href="/" target="_blank">-->
<!--              <i class="ni ni-spaceship"></i>-->
<!--              <span class="nav-link-text">Getting started</span>-->
<!--            </a>-->
<!--          </li>-->
<!--        </ul>-->
      </template>
    </side-bar>
    <div class="main-content" v-show="!loadingInfo && !getNeedInformation">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <router-view></router-view>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
    <div v-show="!loadingInfo && getNeedInformation">
      <information></information>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import SidebarItem from "@/components/SidebarPlugin/SidebarItem.vue";
import Information from "@/views/Dashboard/information.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    Information,
    SidebarItem,
    DashboardNavbar,
    ContentFooter,
  },
  data() {
    return {
      information: {},
      loadingInfo: true,
    };
  },
  computed: {
    ...mapGetters(["getNeedInformation"]),
  },
  methods: {
    ...mapMutations(["saveUser", "needInformation"]),
    ...mapActions(["userInformation"]),
    async getInfo() {
      await this.userInformation();
      this.loadingInfo = false;
    },
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
  },
  mounted() {
    this.getInfo();
    this.initScrollbar();
  },
};
</script>
<style lang="scss"></style>
