<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Subscriptions</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6" v-show="tab !== 'payment' && !currentSubscription">
       <redeem @checkPayment="checkPayment" />
    </div>

    <plans v-show="tab === 'plans'" @selectPlan="selectPlan" />
    <div class="container-fluid mt--6" v-show="tab === 'payment'">
      <div class="row justify-content-center">
        <div class="col-lg-4">
          <!-- Master card -->

          <card
            :gradient="'info'"
            header-classes="bg-transparent"
            footer-classes="bg-transparent"
            body-classes="px-lg-4"
            class="bg-info card-pricing border-0 text-center mb-4"
          >
            <!--Header-->
            <template v-slot:header>
              <h4 class="text-uppercase ls-1 text-white py-3 mb-0">
                {{ selectedPlanData.name }}
              </h4>
            </template>
            <!--Card Body-->
            <ul class="list-unstyled">
              <li
                v-for="(
                  feature, selectedPlanDataIndex
                ) in selectedPlanData.features"
                :key="'selectedPlanData_feature' + selectedPlanDataIndex"
              >
                <div class="d-flex align-items-center">
                  <div>
                    <div
                      class="icon icon-xs icon-shape bg-white shadow rounded-circle"
                    >
                      <i :class="feature.icon"></i>
                    </div>
                  </div>
                  <div>
                    <span class="pl-2 text-sm text-white">{{
                      feature.title
                    }}</span>
                  </div>
                </div>
              </li>
            </ul>
            <!--Footer-->
            <template v-slot:footer>
              <span class="text-white">Month</span>
            </template>
          </card>
          <base-button type="primary" @click="tab = 'plans'" block class="mb-2"
            >Back</base-button
          >
        </div>
        <div class="col-lg-5 ml-lg-3 mr-lg-3">
          <!-- Master card -->
          <credit-card
            v-model:amount="selectedPlanData.price"
            :plan="selectedPlanData"
            @checkPayment="checkPayment"
          />
        </div>
      </div>
    </div>
    <div
      class="container-fluid mt--6"
      v-show="tab === 'exists'"
      v-if="currentSubscription"
    >
      <div class="row">
        <div class="col-lg-3">
          <!-- Master card -->

          <card>
            <!-- Card body -->
            <a href="#!">
              <img
                :src="`https://placehold.co/400x400?text=${currentSubscription.current.plan.name}`"
                class="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                style="width: 140px"
              />
            </a>
            <div class="pt-4 text-center">
              <h5 class="h3 title">
                <span class="d-block mb-1">{{
                  currentSubscription.current.plan.name
                }}</span>
                <a
                  href="#"
                  class="btn btn-sm"
                  :class="{
                    'btn-success': currentSubscription.current.is_active,
                    'btn-danger': !currentSubscription.current.is_active,
                  }"
                  >{{
                    currentSubscription.current.is_active
                      ? "Active"
                      : "Not Active"
                  }}</a
                >
              </h5>
              <h3>
                Expires in:
                <span class="text-primary">{{
                  $filters.FormatDate(currentSubscription.current["end_date"])
                }}</span>
              </h3>
              <small
                class="text-info font-weight-bold"
                v-if="currentSubscription.current.auto_renew"
                >Auto Renew</small
              >
              <div class="mt-3"></div>
            </div>
          </card>
        </div>
        <div class="col-lg-9">
          <!-- Master card -->

          <div class="card">
            <div class="card-header border-0">
              <div class="row">
                <div class="col-6">
                  <h3 class="mb-0">History</h3>
                </div>
                <div class="col-6 text-right">
                  <el-tooltip content="Refresh" placement="top">
                    <base-button
                      type="info"
                      icon
                      size="sm"
                      @click="userSubscriptions"
                    >
                      <span class="btn-inner--icon"
                        ><i class="fas fa-redo-alt"></i
                      ></span>
                      <span class="btn-inner--text">Refresh</span>
                    </base-button>
                  </el-tooltip>
                </div>
              </div>
            </div>

            <el-table
              class="table-responsive table-flush"
              header-row-class-name="thead-light"
              row-key="id"
              :data="currentSubscription.history"
            >
              <el-table-column label="ID" align="left" min-width="120px">
                <template v-slot="{ row }">
                  <b>{{ row.id }}</b>
                </template>
              </el-table-column>

              <el-table-column label="Amount" min-width="220px" prop="Amount">
                <template v-slot="{ row }">
                  <b>{{
                    row.payments.length > 0 ? row.payments[0].amount_format : 0
                  }}</b>
                </template>
              </el-table-column>
              <el-table-column label="Plan" min-width="220px" prop="Plan">
                <template v-slot="{ row }">
                  <b v-if="row.plan">{{ row.plan.name }}</b>
                </template>
              </el-table-column>

              <el-table-column
                label="Start Date"
                prop="createdAt"
                width="170px"
                min-width="140px"
              >
                <template v-slot="{ row }">
                  <b>{{ $filters.FormatDate(row.start_date) }}</b>
                </template>
              </el-table-column>

              <el-table-column
                label="End Date"
                prop="createdAt"
                width="170px"
                min-width="140px"
              >
                <template v-slot="{ row }">
                  <b>{{ $filters.FormatDate(row.end_date) }}</b>
                </template>
              </el-table-column>

              <el-table-column min-width="120px" label="Status" prop="active">
                <template v-slot="{ row }">
                  <div class="d-flex justify-content-center">
                    <base-button
                      :type="
                        row.payments.length > 0
                          ? row.payments[0].status === 'success'
                            ? 'success'
                            : 'danger'
                          : 'primary'
                      "
                      icon
                      size="sm"
                    >
                      <span class="btn-inner--text">{{
                        row.payments.length > 0
                          ? row.payments[0].status === "success"
                            ? "Paid"
                            : "Hold"
                          : "Success"
                      }}</span>
                    </base-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import CreditCard from "@/views/Widgets/CreditCard.vue";
import Plans from "@/views/Subsrcription/component/Plans.vue";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseButton from "@/components/BaseButton.vue";
import Card from "@/components/Cards/Card.vue";
import Redeem from "@/views/Subsrcription/component/Redeem.vue";
import {mapActions} from "vuex";

export default {
  name: "Subscriptions",
  data() {
    return {
      currentSubscription: null,
      tab: "",
      selectedPlanData: {},
    };
  },
  methods: {
    ...mapActions(["userInformation"]),
    selectPlan(plan) {
      this.selectedPlanData = plan;
      this.tab = "payment";
    },
    async checkPayment() {
      this.tab = "exists";
      this.userSubscriptions();
      this.userInformation();
    },
    userSubscriptions() {
      // Get user subscriptions
      this.$api.get("subscription/current").then((res) => {
        const data = res.data.data;
        if (data.current && data.current?.plan.name != "Free") {
          this.currentSubscription = data;
          this.tab = "exists";
        } else {
          this.tab = "plans";
        }
      });
    },
  },
  async mounted() {
    // Check
    await this.checkPayment();
    // Get user subscriptions
  },
  components: {
    Redeem,
    Card,
    BaseButton,
    BaseHeader,
    Plans,
    CreditCard,
    RouteBreadCrumb,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>
