<template>
  <div class="bg-info">
    <!-- Header -->

    <div class="header bg-primary py-6 py-lg-5">
      <div class="header-body text-center mb-7">
        <div class="row justify-content-center">
          <div class="col-xl-5 col-lg-6 col-md-8 px-5">
            <h1 class="text-white">Merchant Dashboard</h1>
            <p class="text-lead text-white">
              Hey 👋🏼, let's reset your password
            </p>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon class="fill-info" points="3560 20 1560 20 0 800"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <Form
                v-show="otp === false"
                @submit="onSubmit"
                :validation-schema="schema"
              >
                <base-input
                  :disabled="loading"
                  alternative
                  name="email"
                  addon-left-icon="fas fa-envelope"
                  placeholder="Email"
                  v-model="model.email"
                >
                </base-input>

                <div class="text-center">
                  <vue-recaptcha
                    v-show="true"
                    sitekey="6LdYAj8pAAAAAMvU-OSNuzvd2hxasvzoyVr2CLK4"
                    size="normal"
                    theme="light"
                    :loading-timeout="30000"
                    @verify="recaptchaVerified"
                    @expire="recaptchaExpired"
                    @fail="recaptchaFailed"
                    @error="recaptchaError"
                    name="capcha"
                    ref="vueRecaptcha"
                    style="display: inline-block"
                  >
                  </vue-recaptcha>
                </div>

                <base-input
                  :disabled="loading"
                  :hidden="true"
                  name="capcha"
                  v-model="model.t"
                >
                </base-input>

                <small class="font-weight-bolder text-danger">{{
                  error_message
                }}</small>
                <div class="text-center">
                  <base-button
                    type="success"
                    native-type="submit"
                    class="my-4"
                    :disabled="loading"
                    >Send OTP
                  </base-button>
                </div>
              </Form>
              <Form
                v-show="otp === true"
                @submit="onSubmitOtp"
                :validation-schema="schemaOtp"
              >
                <base-input
                  :disabled="loading"
                  name="OTP"
                  label="One Time Password"
                  placeholder="321456"
                  v-model="modelOtp.otp"
                >
                  <base-o-t-p
                    :digit-count="6"
                    :value="modelOtp.otp"
                    @updateOTP="(v) => (modelOtp.otp = v)"
                    @submit="onSubmitOtp"
                  ></base-o-t-p>
                </base-input>

                <base-input
                  :disabled="loading"
                  alternative
                  name="password"
                  addon-left-icon="fas fa-key"
                  type="password"
                  placeholder="Password"
                  v-model="modelOtp.password"
                >
                </base-input>

                <div>
                  <span class="small font-weight-bold">Resend OTP: </span>

                  <span v-if="timer > 0" class="small"
                    >Remaining {{ timer }} seconds</span
                  >
                  <span
                    v-else
                    @click="onSubmit"
                    class="text-black-50 small font-weight-bold"
                    style="cursor: pointer"
                    >Send</span
                  >
                </div>
                <small class="font-weight-bolder text-danger">{{
                  error_message
                }}</small>
                <div class="text-center">
                  <base-button
                    type="success"
                    native-type="submit"
                    class="my-4"
                    :disabled="loading"
                    >Reset!
                  </base-button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header">
      <div class="container header-body text-center mb-7">
        <div class="row justify-content-center">
          <div class="col-xl-5 col-lg-6 col-md-8 px-5">
            <p class="text-center text-white mb-8 font-weight-bold">
              Made with 💚 in <img src="img/icons/flags/SA.png" />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form } from "vee-validate";
import * as Yup from "yup";
import BaseOTP from "@/components/Inputs/BaseOTP.vue";
import vueRecaptcha from "vue3-recaptcha2";

export default {
  components: {
    vueRecaptcha,
    BaseOTP,
    Form,
  },
  data() {
    return {
      timer: 2,
      loading: false,
      otp: false,
      error_message: "",
      modelOtp: {
        otp: "",
        temporary_token: "",
        password: "",
      },
      model: {
        email: "",
      },
    };
  },
  methods: {
    recaptchaVerified(response) {
      this.model.t = response;
      console.log(response);
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {},
    recaptchaError(reason) {
      console.log(reason);
    },
    setTimer() {
      this.interval = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        }
      }, 1000);
    },
    onSubmitOtp() {
       // Validate OTP
      if (this.modelOtp.password === "") {
        return;
      }
      this.loading = true;
      this.error_message = "";

      this.$api
        .post("/merchant/reset-password", this.modelOtp)
        .then(async (res) => {
          const data = res.data;
          this.loading = false;
          const token = data.data.token;
          await localStorage.setItem("token", token);
          this.$router.push("/dashboard");
        })
        .catch((error) => {
          this.loading = false;
          this.error_message = error.response.data.message;
        });
    },
    onSubmit() {
      this.timer = 60;
      this.interval = null;
      this.loading = true;
      this.error_message = "";
      this.$api
        .post("/merchant/forgot-password", this.model)
        .then(async (res) => {
          const data = res.data;
          this.loading = false;
          this.otp = true;
          this.modelOtp.temporary_token = data.data.temporary_token;
          this.setTimer();
        })
        .catch((error) => {
          this.loading = false;
          this.error_message = error.response.data.message;
        });
    },
  },
  setup() {
    const schema = Yup.object().shape({
      // Start With 05
      email: Yup.string().email("Email is not valid").required().label("Email"),
      capcha: Yup.string().required().label("Capcha"),
    });
    const schemaOtp = Yup.object().shape({
      // Start With 05
      OTP: Yup.string()
        .min(6, "OTP should be 6 Digits")
        .max(6, "OTP should be 6 Digits")
        .required()
        .label("OTP"),
      password: Yup.string().required().label("Password"),
    });

    return {
      schema,
      schemaOtp,
    };
  },
};
</script>
